import * as React from 'react'
import { graphql } from 'gatsby'

import { Header } from '../../components/common/header'
import { Footer } from '../../components/common/footer'
import { Layout } from '../../layouts'
import { PostItem } from '../../components/blog/post-item'
import './index.sass'

const BlogPage = ({ data, pageContext }) => {
  const { allMdx } = data

  const relevantPosts = allMdx.nodes.filter(item => item.frontmatter.draft === false && item.frontmatter.country === pageContext.country && item.frontmatter.language === pageContext.language)
  return (
    <Layout pageTitle="Blog" country={pageContext.country} language={pageContext.language}>
      <Header linkColor="#272755" buttonColor="#7767E4" country={pageContext.country} />
      <div className="blog-page">
        <h1 className="blog-title">What's new with Ogram</h1>
        <div className="blog-grid">
          {relevantPosts.map(postItem => (
            <PostItem
              key={postItem.frontmatter.path}
              path={postItem.frontmatter.path}
              date={postItem.frontmatter.date}
              type={postItem.frontmatter.type}
              title={postItem.frontmatter.title}
              description={postItem.frontmatter.description}
              imageURL={postItem.frontmatter.image.childImageSharp.original.src}
            />
          ))}
        </div>
      </div>
      <Footer country={pageContext.country} />
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query {
    allMdx(
      filter: {slug: {regex: "$/blog\/"}}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        frontmatter {
          draft
          path
          date(formatString: "dddd, MMMM DD, YYYY")
          type
          title
          description
          country
          language
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
