import React from 'react'
import { Link } from 'gatsby'

import './index.sass'

export const PostItem = ({ path, date, type, title, description, imageURL }) => {
  return (
    <Link
      to={path}
      className="post-item"
    >
      <div className="background-img" style={{ backgroundImage: `url(${imageURL})` }} />
      <div className="header">
        <div className="blog-type-container">
          <span className="blog-type-label">{type}</span>
        </div>
        <span className="date-label">{date}</span>
      </div>
      <span className="title">{title}</span>
      <p className="content">{description}</p>
    </Link>
  )
}